import { datadogRum } from '@datadog/browser-rum'

export default function() {
  // Datadog init
  if (process.env.NODE_ENV === 'prod' || process.env.NODE_ENV === 'stag') {
    datadogRum.init({
      applicationId: process.env.VUE_APP_DATADOG_APPID,
      clientToken: process.env.VUE_APP_DATADOG_TOKEN,
      site: 'datadoghq.com',
      service: `${process.env.NODE_ENV}-makestar-web`,
      env: process.env.NODE_ENV,
      // Specify a version number to identify the deployed version of your application in Datadog
      version: process.env.VUE_APP_VERSION,
      sampleRate: 100,
      premiumSampleRate: 100,
      trackInteractions: true,
      defaultPrivacyLevel: 'mask-user-input',
      allowedTracingOrigins: ['https://www.makestar.co', 'https://staging-makestar.m2076.co.kr', 'https://stage-old.makeuni2026.com', 'https://dev-old.makeuni2026.com']
    })

    datadogRum.startSessionReplayRecording()
  }
}
