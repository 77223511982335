import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _ba9ffc16 = () => interopDefault(import('../pages/artists/index.vue' /* webpackChunkName: "pages/artists/index" */))
const _42026672 = () => interopDefault(import('../pages/chart/index.vue' /* webpackChunkName: "pages/chart/index" */))
const _6096557f = () => interopDefault(import('../pages/faq/index.vue' /* webpackChunkName: "pages/faq/index" */))
const _0146330e = () => interopDefault(import('../pages/lb-health.vue' /* webpackChunkName: "pages/lb-health" */))
const _5baab962 = () => interopDefault(import('../pages/login.vue' /* webpackChunkName: "pages/login" */))
const _db660d3c = () => interopDefault(import('../pages/notice.vue' /* webpackChunkName: "pages/notice" */))
const _4a146eea = () => interopDefault(import('../pages/partnership.vue' /* webpackChunkName: "pages/partnership" */))
const _dbc37de8 = () => interopDefault(import('../pages/password-retrieval.vue' /* webpackChunkName: "pages/password-retrieval" */))
const _0204e327 = () => interopDefault(import('../pages/poca.vue' /* webpackChunkName: "pages/poca" */))
const _486190a3 = () => interopDefault(import('../pages/powerofwhelm.vue' /* webpackChunkName: "pages/powerofwhelm" */))
const _941c5266 = () => interopDefault(import('../pages/qna/index.vue' /* webpackChunkName: "pages/qna/index" */))
const _2ff7f935 = () => interopDefault(import('../pages/search/index.vue' /* webpackChunkName: "pages/search/index" */))
const _f4cf6968 = () => interopDefault(import('../pages/specificCommercialLaw.vue' /* webpackChunkName: "pages/specificCommercialLaw" */))
const _ef7c7ac2 = () => interopDefault(import('../pages/userqna.vue' /* webpackChunkName: "pages/userqna" */))
const _4c009f12 = () => interopDefault(import('../pages/vote/index.vue' /* webpackChunkName: "pages/vote/index" */))
const _2c3bdb0a = () => interopDefault(import('../pages/about/marketing.vue' /* webpackChunkName: "pages/about/marketing" */))
const _07d5966c = () => interopDefault(import('../pages/about/privacy.vue' /* webpackChunkName: "pages/about/privacy" */))
const _a75b702a = () => interopDefault(import('../pages/about/terms.vue' /* webpackChunkName: "pages/about/terms" */))
const _1f0fb9eb = () => interopDefault(import('../pages/payment/order.vue' /* webpackChunkName: "pages/payment/order" */))
const _60403227 = () => interopDefault(import('../pages/payment/paymentRefundStep.vue' /* webpackChunkName: "pages/payment/paymentRefundStep" */))
const _b04487ca = () => interopDefault(import('../pages/payment/paymentRefundStep2.vue' /* webpackChunkName: "pages/payment/paymentRefundStep2" */))
const _5af74b18 = () => interopDefault(import('../pages/popup/invoiceHistory.vue' /* webpackChunkName: "pages/popup/invoiceHistory" */))
const _07e27d5c = () => interopDefault(import('../pages/projects/paymentStep3.vue' /* webpackChunkName: "pages/projects/paymentStep3" */))
const _5350c1bf = () => interopDefault(import('../pages/projects/updates.vue' /* webpackChunkName: "pages/projects/updates" */))
const _0a5fc620 = () => interopDefault(import('../pages/projects/wishlist.vue' /* webpackChunkName: "pages/projects/wishlist" */))
const _380a17d5 = () => interopDefault(import('../pages/projects/wishlist_origin.vue' /* webpackChunkName: "pages/projects/wishlist_origin" */))
const _6cc11f57 = () => interopDefault(import('../pages/result/change-email.vue' /* webpackChunkName: "pages/result/change-email" */))
const _0ce5e1b0 = () => interopDefault(import('../pages/result/change-password.vue' /* webpackChunkName: "pages/result/change-password" */))
const _27b14f55 = () => interopDefault(import('../pages/result/duplication-email.vue' /* webpackChunkName: "pages/result/duplication-email" */))
const _275046de = () => interopDefault(import('../pages/result/invalid-email.vue' /* webpackChunkName: "pages/result/invalid-email" */))
const _7cffd5a1 = () => interopDefault(import('../pages/result/leaveres.vue' /* webpackChunkName: "pages/result/leaveres" */))
const _d73d95cc = () => interopDefault(import('../pages/result/password-retrieval.vue' /* webpackChunkName: "pages/result/password-retrieval" */))
const _66c8bada = () => interopDefault(import('../pages/shop/B2BAccessOnly.vue' /* webpackChunkName: "pages/shop/B2BAccessOnly" */))
const _0de94102 = () => interopDefault(import('../pages/shop/cart.vue' /* webpackChunkName: "pages/shop/cart" */))
const _af06f312 = () => interopDefault(import('../pages/shop/payment.vue' /* webpackChunkName: "pages/shop/payment" */))
const _672bf1cb = () => interopDefault(import('../pages/shop/payment2.vue' /* webpackChunkName: "pages/shop/payment2" */))
const _76508f7a = () => interopDefault(import('../pages/shop/products.vue' /* webpackChunkName: "pages/shop/products" */))
const _58e1b823 = () => interopDefault(import('../pages/signup/send-auth/index.vue' /* webpackChunkName: "pages/signup/send-auth/index" */))
const _3f8a0ed4 = () => interopDefault(import('../pages/signup/send-email.vue' /* webpackChunkName: "pages/signup/send-email" */))
const _50343d90 = () => interopDefault(import('../pages/signup/step1.vue' /* webpackChunkName: "pages/signup/step1" */))
const _50180e8e = () => interopDefault(import('../pages/signup/step2.vue' /* webpackChunkName: "pages/signup/step2" */))
const _7ba7f986 = () => interopDefault(import('../pages/signup/step3/index.vue' /* webpackChunkName: "pages/signup/step3/index" */))
const _042d36da = () => interopDefault(import('../pages/signup/terms.vue' /* webpackChunkName: "pages/signup/terms" */))
const _e2af231a = () => interopDefault(import('../pages/social/auth/index.vue' /* webpackChunkName: "pages/social/auth/index" */))
const _21ea49e3 = () => interopDefault(import('../pages/social/signup/index.vue' /* webpackChunkName: "pages/social/signup/index" */))
const _61890f7d = () => interopDefault(import('../pages/user/leave.vue' /* webpackChunkName: "pages/user/leave" */))
const _8e5c6422 = () => interopDefault(import('../pages/user/profile.vue' /* webpackChunkName: "pages/user/profile" */))
const _4942a5ab = () => interopDefault(import('../pages/v1/imgViewer.vue' /* webpackChunkName: "pages/v1/imgViewer" */))
const _3ffd8efc = () => interopDefault(import('../pages/v1/payment.vue' /* webpackChunkName: "pages/v1/payment" */))
const _1f4c2c1f = () => interopDefault(import('../pages/v1/resultPayment.vue' /* webpackChunkName: "pages/v1/resultPayment" */))
const _fc8df168 = () => interopDefault(import('../pages/contents/events/gayotop10/index.vue' /* webpackChunkName: "pages/contents/events/gayotop10/index" */))
const _05c0bada = () => interopDefault(import('../pages/payment/orders/step.vue' /* webpackChunkName: "pages/payment/orders/step" */))
const _b2274da2 = () => interopDefault(import('../pages/payment/orders/step2.vue' /* webpackChunkName: "pages/payment/orders/step2" */))
const _1a81ef0e = () => interopDefault(import('../pages/social/signup/step1/index.vue' /* webpackChunkName: "pages/social/signup/step1/index" */))
const _682fefd0 = () => interopDefault(import('../pages/social/signup/terms.vue' /* webpackChunkName: "pages/social/signup/terms" */))
const _32a63616 = () => interopDefault(import('../pages/payment/orders/components/invoiceHistoryTable.vue' /* webpackChunkName: "pages/payment/orders/components/invoiceHistoryTable" */))
const _a4c53214 = () => interopDefault(import('../pages/signup/step3/components/input.vue' /* webpackChunkName: "pages/signup/step3/components/input" */))
const _8deff78c = () => interopDefault(import('../pages/social/signup/step1/components/input.vue' /* webpackChunkName: "pages/social/signup/step1/components/input" */))
const _dd380734 = () => interopDefault(import('../pages/contents/articles/detail/_id/index.vue' /* webpackChunkName: "pages/contents/articles/detail/_id/index" */))
const _5035fa15 = () => interopDefault(import('../pages/contents/polls/detail/_id/index.vue' /* webpackChunkName: "pages/contents/polls/detail/_id/index" */))
const _17db498e = () => interopDefault(import('../pages/contents/polls/list/_status/index.vue' /* webpackChunkName: "pages/contents/polls/list/_status/index" */))
const _58b0236e = () => interopDefault(import('../pages/user/me/qna/_qnaNo.vue' /* webpackChunkName: "pages/user/me/qna/_qnaNo" */))
const _0f22f576 = () => interopDefault(import('../pages/contents/articles/_type/index.vue' /* webpackChunkName: "pages/contents/articles/_type/index" */))
const _3d825c36 = () => interopDefault(import('../pages/payment/orders/_type/index.vue' /* webpackChunkName: "pages/payment/orders/_type/index" */))
const _6b37e9c6 = () => interopDefault(import('../pages/projects/list/_type/index.vue' /* webpackChunkName: "pages/projects/list/_type/index" */))
const _f3e4f410 = () => interopDefault(import('../pages/shop/exhibit/_id/index.vue' /* webpackChunkName: "pages/shop/exhibit/_id/index" */))
const _5b82a482 = () => interopDefault(import('../pages/shop/list/_category/_cd/index.vue' /* webpackChunkName: "pages/shop/list/_category/_cd/index" */))
const _39f78f1c = () => interopDefault(import('../pages/projects/_id/index.vue' /* webpackChunkName: "pages/projects/_id/index" */))
const _4a05af20 = () => interopDefault(import('../pages/projects/_id/index/cheer.vue' /* webpackChunkName: "pages/projects/_id/index/cheer" */))
const _36720076 = () => interopDefault(import('../pages/projects/_id/index/notice.vue' /* webpackChunkName: "pages/projects/_id/index/notice" */))
const _d58696c8 = () => interopDefault(import('../pages/projects/_id/index/reward.vue' /* webpackChunkName: "pages/projects/_id/index/reward" */))
const _25622818 = () => interopDefault(import('../pages/projects/_id/index/story.vue' /* webpackChunkName: "pages/projects/_id/index/story" */))
const _4bf38739 = () => interopDefault(import('../pages/projects/_id/index/update/index.vue' /* webpackChunkName: "pages/projects/_id/index/update/index" */))
const _6ade1fd2 = () => interopDefault(import('../pages/projects/_id/index/update/index/detail.vue' /* webpackChunkName: "pages/projects/_id/index/update/index/detail" */))
const _3b718a24 = () => interopDefault(import('../pages/shop/_id/index.vue' /* webpackChunkName: "pages/shop/_id/index" */))
const _742fb4b4 = () => interopDefault(import('../pages/projects/_id/payment.vue' /* webpackChunkName: "pages/projects/_id/payment" */))
const _4f060810 = () => interopDefault(import('../pages/projects/_id/paymentStep2.vue' /* webpackChunkName: "pages/projects/_id/paymentStep2" */))
const _f6017ca4 = () => interopDefault(import('../pages/artists/_id/_category/index.vue' /* webpackChunkName: "pages/artists/_id/_category/index" */))
const _08830038 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/artists",
    component: _ba9ffc16,
    meta: {"name":"Artist","directives":{},"components":{},"filters":{},"middleware":"","computed":{},"methods":{},"pageTitle":"artist","onMenuRouteName":"Artist"},
    name: "artists"
  }, {
    path: "/chart",
    component: _42026672,
    meta: {"name":"Main","components":{},"filters":{},"computed":{},"methods":{},"onMenuRouteName":"Chart"},
    name: "chart"
  }, {
    path: "/faq",
    component: _6096557f,
    meta: {"name":"Example","directives":{},"filters":{},"middleware":"","computed":{},"methods":{},"fullSize":true,"pageTitle":"faq"},
    name: "faq"
  }, {
    path: "/lb-health",
    component: _0146330e,
    meta: {"name":"LBHealthChecker","directives":{},"components":{},"filters":{},"middleware":"","computed":{},"methods":{}},
    name: "lb-health"
  }, {
    path: "/login",
    component: _5baab962,
    meta: {"name":"Login","directives":{},"components":{},"filters":{},"middleware":"","computed":{},"watch":{},"methods":{},"pageTitle":"login","onMenuRouteName":""},
    name: "login"
  }, {
    path: "/notice",
    component: _db660d3c,
    meta: {"name":"NoticeList","directives":{},"components":{},"filters":{},"middleware":"","head":{},"computed":{},"methods":{}},
    name: "notice"
  }, {
    path: "/partnership",
    component: _4a146eea,
    meta: {"name":"Partnership","directives":{},"components":{},"filters":{},"middleware":"","computed":{},"methods":{},"fullSize":false,"onMenuRouteName":"Partner"},
    name: "partnership"
  }, {
    path: "/password-retrieval",
    component: _dbc37de8,
    meta: {"name":"PasswordRetrieval","directives":{},"components":{},"filters":{},"middleware":"","computed":{},"methods":{},"pageTitle":"passwordRetrieval","onMenuRouteName":""},
    name: "password-retrieval"
  }, {
    path: "/poca",
    component: _0204e327,
    meta: {"name":"Poca","components":{},"computed":{},"methods":{},"fullSize":false,"onMenuRouteName":"PocaAlbum","pageTitle":"pocaalbum"},
    name: "poca"
  }, {
    path: "/powerofwhelm",
    component: _486190a3,
    meta: {"name":"Powerofwhelm","computed":{},"watch":{},"methods":{}},
    name: "powerofwhelm"
  }, {
    path: "/qna",
    component: _941c5266,
    meta: {"name":"QnA","directives":{},"components":{},"computed":{},"methods":{},"pageTitle":"qna","onMenuRouteName":"","auth":true},
    name: "qna"
  }, {
    path: "/search",
    component: _2ff7f935,
    meta: {"name":"Search","directives":{},"components":{},"filters":{},"middleware":"","computed":{},"watch":{},"methods":{},"onMenuRouteName":"","pageTitle":"search"},
    name: "search"
  }, {
    path: "/specificCommercialLaw",
    component: _f4cf6968,
    meta: {"name":"AboutPrivacy","directives":{},"components":{},"filters":{},"middleware":"","head":{},"computed":{},"methods":{}},
    name: "specificCommercialLaw"
  }, {
    path: "/userqna",
    component: _ef7c7ac2,
    meta: {"name":"UserQnA","directives":{},"components":{},"filters":{},"middleware":"","computed":{},"methods":{},"pageTitle":"inquiry","onMenuRouteName":"","auth":true},
    name: "userqna"
  }, {
    path: "/vote",
    component: _4c009f12,
    meta: {"name":"Vote","components":{},"pageTitle":"poll","onMenuRouteName":"Vote"},
    name: "vote"
  }, {
    path: "/about/marketing",
    component: _2c3bdb0a,
    meta: {"name":"AboutMarketing","directives":{},"components":{},"filters":{},"middleware":"","methods":{},"pageTitle":"aboutMarketing","onMenuRouteName":""},
    name: "about-marketing"
  }, {
    path: "/about/privacy",
    component: _07d5966c,
    meta: {"name":"AboutPrivacy","directives":{},"components":{},"filters":{},"middleware":"","head":{},"methods":{}},
    name: "about-privacy"
  }, {
    path: "/about/terms",
    component: _a75b702a,
    meta: {"name":"AboutTerms","directives":{},"components":{},"filters":{},"middleware":"","computed":{},"methods":{},"pageTitle":"aboutTerms","onMenuRouteName":""},
    name: "about-terms"
  }, {
    path: "/payment/order",
    component: _1f0fb9eb,
    meta: {"name":"NewProductOrder","components":{},"computed":{"phone":{},"paymentType":{},"deliveryCode":{},"selectCountry":{},"zipCode":{},"address":{},"rewardPrice":{}},"methods":{}},
    name: "payment-order"
  }, {
    path: "/payment/paymentRefundStep",
    component: _60403227,
    meta: {"name":"PaymentRefundStep","computed":{},"watch":{},"methods":{},"auth":true},
    name: "payment-paymentRefundStep"
  }, {
    path: "/payment/paymentRefundStep2",
    component: _b04487ca,
    meta: {"name":"PaymentRefundStep","computed":{},"watch":{},"methods":{},"auth":true},
    name: "payment-paymentRefundStep2"
  }, {
    path: "/popup/invoiceHistory",
    component: _5af74b18,
    meta: {"name":"InvoiceHistory","components":{},"computed":{},"methods":{},"pageTitle":"deliveryTracking","auth":true},
    name: "popup-invoiceHistory"
  }, {
    path: "/projects/paymentStep3",
    component: _07e27d5c,
    meta: {"name":"PaymentStep2","computed":{},"methods":{},"auth":true,"pageTitle":"payment"},
    name: "projects-paymentStep3"
  }, {
    path: "/projects/updates",
    component: _5350c1bf,
    meta: {"name":"Search","directives":{},"components":{},"filters":{},"middleware":"","computed":{},"methods":{},"pageTitle":"update","onMenuRouteName":""},
    name: "projects-updates"
  }, {
    path: "/projects/wishlist",
    component: _0a5fc620,
    meta: {"name":"Wishlist","components":{},"computed":{},"methods":{},"auth":true,"onMenuRouteName":"Wishlist","fullSize":true,"pageTitle":"wishlist"},
    name: "projects-wishlist"
  }, {
    path: "/projects/wishlist_origin",
    component: _380a17d5,
    meta: {"name":"Wishlist","components":{},"head":{"title":"Shop | K-POP Makestar"},"computed":{},"methods":{},"auth":true,"onMenuRouteName":"Wishlist","fullSize":true},
    name: "projects-wishlist_origin"
  }, {
    path: "/result/change-email",
    component: _6cc11f57,
    meta: {"name":"PasswordRetrievalResult","directives":{},"components":{},"filters":{},"middleware":"","computed":{},"methods":{},"pageTitle":"passwordRetrieval","onMenuRouteName":""},
    name: "result-change-email"
  }, {
    path: "/result/change-password",
    component: _0ce5e1b0,
    meta: {"name":"PasswordRetrievalResult","directives":{},"components":{},"filters":{},"middleware":"","computed":{},"methods":{},"pageTitle":"passwordRetrieval","onMenuRouteName":""},
    name: "result-change-password"
  }, {
    path: "/result/duplication-email",
    component: _27b14f55,
    meta: {"name":"DuplicationEmail","directives":{},"components":{},"filters":{},"middleware":"","computed":{},"methods":{},"pageTitle":"","onMenuRouteName":""},
    name: "result-duplication-email"
  }, {
    path: "/result/invalid-email",
    component: _275046de,
    meta: {"name":"PasswordRetrievalResult","directives":{},"components":{},"filters":{},"middleware":"","computed":{},"methods":{},"pageTitle":"invalidEmail","onMenuRouteName":""},
    name: "result-invalid-email"
  }, {
    path: "/result/leaveres",
    component: _7cffd5a1,
    meta: {"name":"LeaveRes","directives":{},"components":{},"filters":{},"middleware":"","computed":{},"watch":{},"methods":{},"pageTitle":"회원탈퇴","onMenuRouteName":"","fullSize":true},
    name: "result-leaveres"
  }, {
    path: "/result/password-retrieval",
    component: _d73d95cc,
    meta: {"name":"PasswordRetrievalResult","directives":{},"components":{},"filters":{},"middleware":"","computed":{},"methods":{},"pageTitle":"passwordRetrieval","onMenuRouteName":""},
    name: "result-password-retrieval"
  }, {
    path: "/shop/B2BAccessOnly",
    component: _66c8bada,
    meta: {"name":"B2BAccessOnly","components":{},"computed":{},"methods":{},"fullSize":false,"onMenuRouteName":"B2BAccessOnly"},
    name: "shop-B2BAccessOnly"
  }, {
    path: "/shop/cart",
    component: _0de94102,
    meta: {"name":"Cart","components":{},"computed":{},"methods":{},"auth":true,"onMenuRouteName":"Cart","fullSize":true,"pageTitle":"cart"},
    name: "shop-cart"
  }, {
    path: "/shop/payment",
    component: _af06f312,
    meta: {"name":"Payment","computed":{},"watch":{},"methods":{},"auth":true,"pageTitle":"payment"},
    name: "shop-payment"
  }, {
    path: "/shop/payment2",
    component: _672bf1cb,
    meta: {"name":"ShopStep","components":{},"computed":{},"methods":{},"auth":true,"pageTitle":"payment"},
    name: "shop-payment2"
  }, {
    path: "/shop/products",
    component: _76508f7a,
    meta: {"name":"ShopProducts","directives":{},"filters":{},"middleware":"","computed":{},"watch":{},"methods":{},"onMenuRouteName":"Shop","fullSize":true},
    name: "shop-products"
  }, {
    path: "/signup/send-auth",
    component: _58e1b823,
    meta: {"name":"SendAuth","directives":{},"components":{},"filters":{},"middleware":"","computed":{},"watch":{},"methods":{}},
    name: "signup-send-auth"
  }, {
    path: "/signup/send-email",
    component: _3f8a0ed4,
    meta: {"name":"SignUp","directives":{},"components":{},"filters":{},"middleware":"","computed":{},"methods":{},"pageTitle":"signup","onMenuRouteName":""},
    name: "signup-send-email"
  }, {
    path: "/signup/step1",
    component: _50343d90,
    meta: {"name":"SignUpStep1","directives":{},"components":{},"filters":{},"middleware":"","computed":{},"watch":{},"methods":{},"pageTitle":"signup","onMenuRouteName":""},
    name: "signup-step1"
  }, {
    path: "/signup/step2",
    component: _50180e8e,
    meta: {"name":"SignUpStep2","directives":{},"filters":{},"middleware":"","props":{},"computed":{},"watch":{},"methods":{},"pageTitle":"signup","onMenuRouteName":""},
    name: "signup-step2"
  }, {
    path: "/signup/step3",
    component: _7ba7f986,
    meta: {"name":"Step2","directives":{},"filters":{},"components":{},"middleware":"","props":{},"computed":{},"methods":{},"pageTitle":"signup","onMenuRouteName":""},
    name: "signup-step3"
  }, {
    path: "/signup/terms",
    component: _042d36da,
    meta: {"name":"SignUpTerms","directives":{},"components":{},"filters":{},"middleware":"","computed":{},"methods":{},"pageTitle":"signup","onMenuRouteName":""},
    name: "signup-terms"
  }, {
    path: "/social/auth",
    component: _e2af231a,
    meta: {"name":"SocialAuthPopup","methods":{}},
    name: "social-auth"
  }, {
    path: "/social/signup",
    component: _21ea49e3,
    meta: {"name":"SocialSignup","directives":{},"components":{},"filters":{},"middleware":"","computed":{},"methods":{},"pageTitle":"signup","onMenuRouteName":""},
    name: "social-signup"
  }, {
    path: "/user/leave",
    component: _61890f7d,
    meta: {"name":"Leave","directives":{},"components":{},"filters":{},"middleware":"","computed":{},"watch":{},"methods":{},"pageTitle":"leave","onMenuRouteName":"","fullSize":true,"auth":true},
    name: "user-leave"
  }, {
    path: "/user/profile",
    component: _8e5c6422,
    meta: {"name":"UserProfile","directives":{},"components":{},"filters":{},"middleware":"","computed":{},"watch":{},"methods":{},"pageTitle":"profile","onMenuRouteName":"","fullSize":true,"auth":true},
    name: "user-profile"
  }, {
    path: "/v1/imgViewer",
    component: _4942a5ab,
    meta: {"name":"ImgViewer"},
    name: "v1-imgViewer"
  }, {
    path: "/v1/payment",
    component: _3ffd8efc,
    meta: {"name":"Payment"},
    name: "v1-payment"
  }, {
    path: "/v1/resultPayment",
    component: _1f4c2c1f,
    meta: {"name":"ResultPayment","computed":{},"methods":{}},
    name: "v1-resultPayment"
  }, {
    path: "/contents/events/gayotop10",
    component: _fc8df168,
    meta: {"name":"GayoTop10","directives":{},"components":{},"filters":{},"middleware":"","computed":{},"methods":{},"pageTitle":"GayoTop10","onMenuRouteName":"GayoTop10","fullSize":true},
    name: "contents-events-gayotop10"
  }, {
    path: "/payment/orders/step",
    component: _05c0bada,
    meta: {"name":"PaymentOrdersDetail","components":{},"computed":{},"watch":{},"methods":{},"auth":true,"fullSize":false,"pageTitle":"orderDetail"},
    name: "payment-orders-step"
  }, {
    path: "/payment/orders/step2",
    component: _b2274da2,
    meta: {"name":"PaymentOrdersDetail","components":{},"computed":{},"methods":{},"auth":true,"fullSize":false},
    name: "payment-orders-step2"
  }, {
    path: "/social/signup/step1",
    component: _1a81ef0e,
    meta: {"name":"Step2","directives":{},"filters":{},"components":{},"middleware":"","props":{},"computed":{},"methods":{},"pageTitle":"signup","onMenuRouteName":""},
    name: "social-signup-step1"
  }, {
    path: "/social/signup/terms",
    component: _682fefd0,
    meta: {"name":"SocialSignUpTerms","computed":{},"methods":{},"pageTitle":"signup","onMenuRouteName":""},
    name: "social-signup-terms"
  }, {
    path: "/payment/orders/components/invoiceHistoryTable",
    component: _32a63616,
    meta: {"props":["invoiceList","dlvy","trackingUrl"],"computed":{},"methods":{}},
    name: "payment-orders-components-invoiceHistoryTable"
  }, {
    path: "/signup/step3/components/input",
    component: _a4c53214,
    meta: {"props":{"validRegexResult":{},"validRegex":{},"value":{"default":""},"placeholder":{"default":""},"key1":{"default":""},"componentType":{"default":"text"},"max":{"default":0}},"watch":{},"methods":{}},
    name: "signup-step3-components-input"
  }, {
    path: "/social/signup/step1/components/input",
    component: _8deff78c,
    meta: {"props":{"validRegexResult":{},"validRegex":{},"value":{"default":""},"placeholder":{"default":""},"key1":{"default":""},"componentType":{"default":"text"},"max":{"default":0}},"watch":{},"methods":{}},
    name: "social-signup-step1-components-input"
  }, {
    path: "/contents/articles/detail/:id",
    component: _dd380734,
    meta: {"name":"EventDetail","components":{},"computed":{},"methods":{},"fullSize":true},
    name: "contents-articles-detail-id"
  }, {
    path: "/contents/polls/detail/:id",
    component: _5035fa15,
    meta: {"name":"PollsDetail","components":{},"computed":{},"methods":{}},
    name: "contents-polls-detail-id"
  }, {
    path: "/contents/polls/list/:status",
    component: _17db498e,
    meta: {"name":"Polls","directives":{},"components":{},"filters":{},"middleware":"","computed":{},"methods":{},"onMenuRouteName":"Event","fullSize":true},
    name: "contents-polls-list-status"
  }, {
    path: "/user/me/qna/:qnaNo?",
    component: _58b0236e,
    meta: {"name":"QnADetail","directives":{},"components":{},"filters":{},"middleware":"","computed":{},"watch":{},"methods":{},"pageTitle":"qnaInfo","onMenuRouteName":"","auth":true},
    name: "user-me-qna-qnaNo"
  }, {
    path: "/contents/articles/:type",
    component: _0f22f576,
    meta: {"name":"Articles","directives":{},"filters":{},"components":{},"middleware":"","computed":{},"methods":{},"onMenuRouteName":"Event"},
    name: "contents-articles-type"
  }, {
    path: "/payment/orders/:type",
    component: _3d825c36,
    meta: {"name":"Orders","components":{},"computed":{},"methods":{},"auth":true,"fullSize":true,"pageTitle":"payments"},
    name: "payment-orders-type"
  }, {
    path: "/projects/list/:type",
    component: _6b37e9c6,
    meta: {"name":"ProjectArtist","components":{},"computed":{},"methods":{},"onMenuRouteName":"Project","fullSize":true,"pageTitle":"project"},
    name: "projects-list-type"
  }, {
    path: "/shop/exhibit/:id",
    component: _f3e4f410,
    meta: {"components":{},"filters":{},"head":{"title":"Shop | K-POP Makestar","fullSize":true},"computed":{},"methods":{},"onMenuRouteName":"Shop","fullSize":true},
    name: "shop-exhibit-id"
  }, {
    path: "/shop/list/:category?/:cd",
    component: _5b82a482,
    meta: {"name":"ShopProducts","components":{},"computed":{},"watch":{},"methods":{},"onMenuRouteName":"Shop","fullSize":true,"pageTitle":"shop"},
    name: "shop-list-category-cd"
  }, {
    path: "/projects/:id",
    component: _39f78f1c,
    meta: {"name":"Index","components":{},"computed":{},"methods":{},"fullSize":true},
    name: "projects-id",
    children: [{
      path: "cheer",
      component: _4a05af20,
      name: "projects-id-index-cheer"
    }, {
      path: "notice",
      component: _36720076,
      name: "projects-id-index-notice"
    }, {
      path: "reward",
      component: _d58696c8,
      name: "projects-id-index-reward"
    }, {
      path: "story",
      component: _25622818,
      name: "projects-id-index-story"
    }, {
      path: "update",
      component: _4bf38739,
      name: "projects-id-index-update",
      children: [{
        path: "detail",
        component: _6ade1fd2,
        name: "projects-id-index-update-index-detail"
      }]
    }]
  }, {
    path: "/shop/:id",
    component: _3b718a24,
    meta: {"name":"","filters":{},"computed":{},"components":{},"watch":{"undefined":{}},"methods":{}},
    name: "shop-id"
  }, {
    path: "/projects/:id?/payment",
    component: _742fb4b4,
    meta: {"name":"Payment","components":{},"computed":{},"watch":{},"methods":{},"auth":true,"pageTitle":"payment"},
    name: "projects-id-payment"
  }, {
    path: "/projects/:id?/paymentStep2",
    component: _4f060810,
    meta: {"name":"PaymentStep2","components":{},"computed":{},"watch":{},"methods":{},"auth":true,"pageTitle":"payment"},
    name: "projects-id-paymentStep2"
  }, {
    path: "/artists/:id/:category",
    component: _f6017ca4,
    meta: {"name":"Artist","directives":{},"components":{},"filters":{},"middleware":"","computed":{},"methods":{},"pageTitle":"artists","onMenuRouteName":"Artist"},
    name: "artists-id-category"
  }, {
    path: "/",
    component: _08830038,
    meta: {"name":"Home","directives":{},"components":{},"filters":{},"computed":{},"watch":{},"methods":{},"fullSize":true},
    name: "index"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
