// api/modules/luckydraw.js
export default function(request) {
  return {
    checkIsFirstPayment(data) {
      /**
       * 럭키드로우 서버 첫 결제 여부 확인
       * @param {{project_id: string,start_date: null || string, end_date: null || string}} data
       * @returns {Promise<{ project_id: string, is_luckydraw_project: boolean, is_first_payment: boolean}>}
       */

      const { project_id, start_date, end_date } = data
      let url = `/pay/check/luckydraw?project_id=${project_id}`

      if (start_date) {
        url += `&start_date=${data.start_date}`
      }
      if (end_date) {
        url += `&end_date=${data.end_date}`
      }

      // config 설정
      const config = {
        headers: {
          'Content-Type': 'application/json'
        }
      }

      return request.$get(url, config)
    },
    setRefreshTokenToLuckyDraw(data) {
      /**
       * 럭키드로우 서버 refreshToken 설정
       * @param {{refresh_token: string}} data
       * @returns {Promise}
       */

      const url = 'auth/refresh_token'
      const config = {
        headers: {
          'Content-Type': 'application/json'
        }
      }

      return request.$put(url, data, config)
    },
    buildLuckyDrawUrl(data) {
      /**
       * 럭키드로우 Url 생성
       * @param {{access_token: string, project_id: string}} data
       * @returns {Promise}
       */
      const { access_token, project_id } = data
      if (!access_token) {
        return `${process.env.LUCKY_DRAW_URL}?access_token=undefined&project_id=${project_id}`
      }
      return `${process.env.LUCKY_DRAW_URL}?access_token=${access_token}&project_id=${project_id}`
    }
  }
}
