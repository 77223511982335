import React from 'react'
import styled from '@emotion/styled';
import { C1Medium } from '~/react/Foundation/Typography'
import { DropDown } from '~/react/Common/Select/DropDown'
import { gray } from '~/react/Foundation/Color'

const DropdownWrap = styled.div`
  margin: 24px 0 16px 0;
  width: 176px;
`;

const MenuWrap = styled.div`
  display: flex;
  align-items: center;
  align-content: center;
  gap: 6px 12px;
  max-width: 367px;
  flex-wrap: wrap;
`;

const MenuButton = styled(C1Medium)`
  color: ${gray[70]};
  padding: 2px 0;
`;

const Separator = styled.span`
  width: 1px;
  height: 10px;
  background-color: ${gray[40]};
`;

export function WebsiteFooter({ $i18n, navigateTo, onSelectLanguage }) {
  const LANGUAGES = [
    { key: 'ko', value: $i18n.t('common.ko') },
    { key: 'en', value: $i18n.t('common.en') },
    { key: 'zh', value: $i18n.t('common.zh') },
    { key: 'ja', value: $i18n.t('common.ja') },
    { key: 'es', value: $i18n.t('common.es') }
  ]

  const MENUS = [
    { name: $i18n.t('footer.about'), type: 'NEW_PAGE', link: 'http://www.makestarcorp.com' },
    // { name: 'Actors', type: 'NEW_PAGE', link: 'https://www.makestarcorp.com/actors' },
    { name: $i18n.t('footer.recruit'), type: 'NEW_PAGE', link: 'https://makestarrecruit.oopy.io' },
    { name: 'PocaAlbum', type: 'ROUTE', link: '/poca' },
    { name: $i18n.t('footer.partnershipInquiry'), type: 'ROUTE', link: '/partnership' }
  ]

  const onClickFamilySiteLink = (path, type) => {
    if (type === 'ROUTE') {
      navigateTo(path)
    }
    if (type === 'NEW_PAGE') {
      window.open(path)
    }
  }

  return (
    <>
      <DropdownWrap>
        <DropDown
          itemList={LANGUAGES}
          onSelectItem={onSelectLanguage}
          selectedItemKey={$i18n.locale}
          scrollable={false}
        />
      </DropdownWrap>
      <MenuWrap>
        {MENUS.map((menu, index) => (
          <>
            <MenuButton
              as="button"
              onClick={() => onClickFamilySiteLink(menu.link, menu.type)}>
              {menu.name}
            </MenuButton>
            {index !== (MENUS.length - 1) && <Separator/>}
          </>
        ))}
      </MenuWrap>
    </>
  )
}
